<script setup lang="ts">
</script>

<template>
  <div>
    <Header />
    <div v-if="useContext() === 'operator'" class="white-container">
      <LazySearchPanel class="layout-search-panel" />
    </div>
    <div>
      <slot></slot>
    </div>
    <Footer />
  </div>
</template>

<style scoped lang="scss">
.layout-search-panel {
  padding-top: 8px;
  margin-bottom: 24px;
  @media (max-width: 1023px) {
    margin-top: 12px;
    margin-bottom: 16px;
  }
}
</style>
